import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
ElementUI.Form.props.size = { default: "small" };
ElementUI.Button.props.size = { default: "small" };
ElementUI.Input.props.clearable.default = true;
ElementUI.Select.props.clearable = { default: true };
Vue.use(ElementUI);

// 引入自定义样式
import "./scss/elementui.scss";

// 绑定 api
import api from "./util/api";
Vue.prototype.$api = api;

// 全局管理地图访问key
Vue.prototype.$mapKey = "a15ec8b7b54e3da437193963e6e69654";

// 全局引入 状态管理器 pinia
import { createPinia, PiniaVuePlugin } from 'pinia'
// 创建pinia
Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(pinia);
// 挂载 pinia
import { Store } from "./store";
const store = Store();
Vue.prototype.$store = store;

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
